$font-stack:                              'Jost-400-Book', 'Open Sans',sans-serif;
$bg-color:                                #000;
$page-bg-color:                            #e0e0e0;
$primary:                                 #f58426;
$secondary:                               #757575;
$white-color:                             #fff;
$primary-text-color:                      #212529;
$logos-color:                             #454545;

$admo-color:                              #f58426;
$pharos-color:                            #8dc73f;
$delta-color:                             #fec709;

$border-radius:                             6px;
$input-btn-padding-x:                       1.875rem;
$btn-padding-y:                             0.75rem;
$btn-padding-x:                             0.75rem;

// Custom checkbox
$custom-control-gutter:                     4rem;
$custom-control-spacer-x:                   3rem;
$custom-control-indicator-size:             3rem;
$custom-control-indicator-bg:               $white-color;
$custom-control-indicator-checked-color:  #757575;
$custom-control-indicator-checked-bg:       $white-color;


@import "~bootstrap/scss/bootstrap";
@import "~open-iconic/font/css/open-iconic-bootstrap.scss";
@import "./selectize.css";


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Open_Sans/OpenSans-Light.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Open_Sans/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Jost/Jost-Light.ttf);
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Jost/Jost-SemiBold.ttf);
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Jost/Jost-Bold.ttf);
}

@font-face {
  font-family: 'Jost-400-Book';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Jost/Jost-400-Book.ttf); }

html, body {
  height: 100%;
}

body {
  font-family: $font-stack;
  font-weight: 300;
  background-color: $bg-color;
}

// Forms
.form-login{
  width: 100%;
  max-width: 555px;
  // padding: 0.9375rem 0;
  margin: auto;
  text-align: center;

  .help-link{
    font-size: 1.25rem;
    font-family: 'Jost';
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
  }

  h1{
    min-height: 186px;
    font-size: 3rem;
  }
}

.form-control{
  height: calc(3.5rem);
  font-size: 1.5rem;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $white-color inset;
  -webkit-text-fill-color: #454545 !important;
  transition: background-color 5000s ease-in-out 0s;
}

// Buttons
.btn{
  font-size: 1.25rem;
  font-family: 'Jost-400-Book';
  font-weight: 300;
  text-transform: uppercase;
}

.btn-outline-secondary{
  border-width: 2px;
  line-height: 1.75rem;
  color: $white-color;
}

.logo{
  width: 550px;
  //margin-bottom: 1.75rem;
}

.former {
  width: 270px;
  margin: 0 auto 1.75rem auto;
}



.logo-wrap{
  margin-bottom: 2.125rem;
  text-align: center;

  .logo{
    width: 40px;
    height: 40px;
    margin-bottom: 1.625rem;
  }

  .logo-text{
    font-size: 1.125rem;
    font-family: 'Jost';
    font-weight: 700;
    text-transform: uppercase;
  }
}

.main-content{
  min-height: 100%;
  margin: 0;
  // overflow: auto;

  & > div{
    position: relative;
    padding: 1.875rem 0.9375rem;
  }
}

.help-links {
  margin-top: 1.5em;
}
// apps-container
.apps-container{
  position: relative;
  background-color: #333;
  color: #aaa;
  // overflow: hidden;

  .app-logo{
    height: 2.125rem;
    margin: 0 0 1.375rem;
  }

  h2{
    margin: 0 0 2rem;
    font-size: 2rem;

    &.lead{
      min-height: 186px;
    }
  }

  .app-list{
    width: 100%;
    margin: auto;

    .col-xl-6{
      padding: 0;
    }

    .app-item{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // width: 260px;
      height: 130px;
      margin: 0.25rem 0;
      border-radius: 0.8rem;
      background-color: #f6f6f6;
      text-decoration: none;

      .inactive-text {
         display: none;
      }

      .active-text {
        display: block;
        margin: 0.625rem 0 0 -1rem;
        text-transform: uppercase;
        color: #757575;
        font-family: 'Jost';
        font-weight: 600;
      }

      .other-item {
        color: $primary-text-color;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
      }

      &.inactive{
        flex-direction: column;
        background-color: #454545;
        -o-transition:.7s;
        -moz-transition:.7s;
        -webkit-transition:.7s;
        transition:.7s;

        &:hover {
          background-color: $page-bg-color;
        }

        .inactive-text{
          display: block;
          margin: 0.625rem 0 0 -1rem;
          text-transform: uppercase;
          color: #757575;
          font-family: 'Jost';
          font-weight: 600;
        }

        .active-text {
          display: none;
        }
      }

      img{
        height: 35px;
      }
    }
  }
}

.close{
  position: absolute;
  top: 0;
  right: 0;
  padding: 32px 37px;
  background-color: #242424;
  color: $white-color;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 300;
  text-shadow: none;
  z-index: 1;
  opacity: .5;

  span {
    /*opacity: .3;*/
    &.pharos {
      color: $pharos-color;
    }
    &.admo {
      color: $admo-color;
    }
    &.delta {
      color: $delta-color;
    }
  }


  img{
    height: 32px;
    width: 32px;


  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus{
    color: $white-color;
  }
}

// Dashboard List
.dashboard-list{
  padding: 0;
  margin: 0;
  list-style: none;

  &-item{
    position: relative;
    display: block;
    padding: 0.9375rem 1.625rem;
    background-color: #cba214;
    color: $white-color;
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: $border-radius;
    overflow: hidden;

    &:hover{
      color: $white-color;
      text-decoration: none;
      background-color: darken($color: #cba214, $amount: 5);
    }

    &:after{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 65px;
      background-color: darken($color: #cba214, $amount: 5);
      content: "→";
      line-height: 60px;
      text-align: center;
    }
  }

  & li:not(:first-child){
    .dashboard-list-item{
      margin-top: 0.5rem;
    }
  }
}

/* Circles */
.bg-pie{
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 400px;
  overflow: hidden;

  &:after{
    position: absolute;
    top: -400px;
    right: -400px;
    content: "";
    width: 800px;
    height: 800px;
    border: 200px solid transparent;
    opacity: .1;
    border-radius: 50%;
  }

  &.yellow:after{
    border-color: $delta-color;
  }

  &.green:after{
    border-color: $pharos-color;
  }

  &.orange:after{
    border-color: $admo-color;
  }
}

.search-wrap{
  .search-btn{
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    padding: 0.5rem;
    margin-left: 0.5rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

// Selectize
.selectize{
  &-control{
    &.multi {
      .selectize-input.has-items {
        padding: 10px 25px;
      }
    }

    .selectize-input.has-options >.item,
    .selectize-input.has-options >.item.active {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
      line-height: 34px;
      background: none;
    }
  }

  &-input{
    padding: 10px 25px;
    line-height: 34px;
    border-radius: 6px;

    .form-control{
      height: auto;
      font-size: 1.5rem;
      line-height: 28px;
      text-align: left;
    }

    &:not(.has-items){
      .form-control{
        width: 100%!important;
      }
    }
  }
}



.item-tags{
  margin: 0 0 16px;
  padding: 0;
  list-style: none;

  li{
    position: relative;
    padding: 16px 25px;
    margin-bottom: 8px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    background-color: #8dc73f;
    border-radius: 6px;
  }

  .close{
    position: absolute;
    width: 60px;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.1);
    border-radius: 0 6px 6px 0;
    opacity: .8;
    transition: all ease 0.3s;
  }
}
.item-tags.race-tags {
  li {
    background-color: #F58426;
  }
}
.pt-perspective {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
  overflow: hidden;
}

.pt-page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: auto;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-color: $page-bg-color;

  // &>div {
  //   height: 100%;
  // }
}

.pt-page-current {
  visibility: visible;
  z-index: 1;
}

.pt-page-ontop {
  z-index: 999;
}

.faq-body {
  background-color: $page-bg-color;
  color: $primary-text-color;
  margin-top: 30px;

  .faq-container {
    width: 75%;
    margin: 0 auto;

    .logo {
      width: 400px;
    }
    .header {
      color: $logos-color;
      font-family: 'Jost';
      font-size: 3.2em;
      font-weight: bold;
    }
  }

  .app-logo {
    width: 180px;
    margin: 30px 0 0 0;

    &-pharos {
      width: 220px;
    }
  }

  .pharos-information-icon {
    width: 30px;
    height: 30px;
  }

  .question {
    margin-top: 2em;
    font-weight: bold;
  }
}

// Responsive styles
@include media-breakpoint-up(md) {
  .btn{
    padding-top: 1.375rem;;
    padding-bottom: 1.375rem;;
  }

  .form-login{
    // padding: 0.9375rem;
  }

  .form-control{
    height: calc(3.75rem);
  }

  .apps-container{
    .app-list{
      // padding: 25px 75px;
    }
  }

  .main-content{
    & > div{
      padding: 60px 80px;
    }
  }

  .apps-container {
    .app-list {
      // padding: 0.9375rem;

      .app-item{
        height: 130px;
        margin: 0.25rem;
      }
    }
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height:60px;
  width:60px;
  margin:0px auto;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border: 6px solid rgba(79, 79, 79, 0.15);
  border-top-color: rgba(20, 20, 20, 0.8);
  border-radius:100%;
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}

/* Subscription Settings */
.subsc-content {
  label{
      display: block;
      text-align: left;
  }

  .subsc-tabs {
    label {
      display: block;
      text-align: left;
    }
  }

  table {
    width: 100%;
    text-align: center;
  }

  .admo-table {
    th:first-child {
      width: 80%;
    }
  }

  .delta-table {
    th:first-child {
        width: 90%;
      }
  }

  table {
    td:first-child {
      text-align: left;
    }

    td label {
      text-align: center;
    }
  }

  .custom-control-label:before,
  .custom-control-label:after {
    top: 0;
    left: -3rem;
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: none !important;
  }

  .custom-control-label:before{
    border: 1px solid $primary;
  }

  .subsc-tab {
    min-height: 230px;
  }

  table,
  label {
    line-height: 32px;
  }

  .subsc-submit {
    margin: 10px;
  }

  h2 {
    padding: 30px 15px 15px !important;
  }

  .close{
    color:$primary;
  }

  .subsc-tab-first fieldset {
    margin-top: 32px;

    > div {
      padding-left: 3rem;
    }
  }
}

#safari-popup .modal-content::before {
  content: "";
  background: url(../img/adimpact_logo.png);
  opacity: 0.2;
  top: -65px;
  left: -125px;
  bottom: 0;
  right: 0;
  position: absolute;
  background-size: 2100px;
}

#safari-popup .title-row{
  font-size: 66px;
}

#safari-popup .middle-row{
  font-size: 22px;
}

#safari-popup .last-row{
  font-size: 17px;
}

@import "./animations.css";

